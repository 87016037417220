<template>

    <div
        class="layout d-flex flex-column min-vh-100">

        <Header />

        <div class="breadcrumbs-component">
            <div class="container">
                <Breadcrumb :items="breadcrumb"/>
            </div>
        </div>

        <main class="main pb-3">
            <div class="container">

                <router-view />

            </div>
        </main>

        <Footer />

    </div>

</template>

<script>

    import Header from '@/components/Header/Index'
    import Breadcrumb from '@/components/UI/Breadcrumb'
    import Footer from '@/components/Footer'

    export default {
        name: "DefaultLayout",
        components: {
            Header,
            Breadcrumb,
            Footer
        },
        data: () => ({
            breadcrumb: []
        }),
        watch: {
            $route () {
                this.setBreadcrumb()
            }
        },
        methods: {
            setBreadcrumb () {
                this.breadcrumb = this.$route.meta.breadcrumb
            }
        },
        mounted() {
            this.setBreadcrumb()
        }
    }
</script>

<style scoped>

</style>
