import axios from "axios";

const actions = {
    sendApproveOrder(ctx, data) {
        return new Promise((resolve, reject) => {
            axios.post('/orders/create', data).then(resp => {
                console.log(resp)
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    editOrder(ctx, {id, data}) {
        return new Promise((resolve, reject) => {
            axios.put(`/orders/${id}`, data).then(resp => {
                console.log(resp)
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    actions
}
