<template>
  <div class="header-top py-1">
    <div class="container">
      <div class="d-flex justify-content-between">
        <HeaderLogo />

        <div class="header-account d-flex align-items-center">
          <router-link
            to="/profile"
            class="header-acc-name text-right mr-3 d-block text-decoration-none"
          >
            <div class="font-weight-bold h6 mb-0 text-dark">
              {{ user.name }}
            </div>
            <div class="text-muted" style="font-size: 1rem">{{ getRole }}</div>
          </router-link>
          <a
            href="#"
            class="d-flex align-items-center justify-content-center border rounded-circle m-0 pr-1"
            style="width: 3.33rem; height: 3.33rem"
            @click.prevent="logout"
          >
            <BIcon icon="box-arrow-in-right" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "@/components/Header/Logo";
import getRole from "@/hooks/roles";

export default {
  name: "HeaderTop",
  components: {
    HeaderLogo,
  },
  computed: {
    user() {
      return this.$store.state.account.user;
    },
    getRole() {
      return getRole(this.user.roles[0]);
    },
  },
  methods: {
    logout() {
      const redirectUri = window.location.href;
      const url = new URL(this.$keycloak.createLogoutUrl());
      const query = `post_logout_redirect_uri=${redirectUri}&client_id=${this.$keycloak.clientId}`;

      window.location.href = `${url.origin}${url.pathname}?${query}`;

      // this.$keycloak.logout({
      //   redirectUri
      // });
    },
  },
};
</script>

<style scoped></style>
