import axios from "axios";

const state = () => ({
    negotiationsReport: [],
    currentNegotiation: {
        number: '',
        date: '',
        positions: []
    }
})

const mutations = {
    setNegotiationsReport (state, data) {
        state.negotiationsReport = data
    },
    setCurrentNegotiation (state, data) {
        state.currentNegotiation = data
    }
}

const actions = {
    async getNegotiationsReport ({ commit }, filters) {
        let params = {}
        if (filters) {
            params = filters
        }
        const res = await axios.get('/price-negotiations/report', {
            params
        })
        commit('setNegotiationsReport', res.data)
    },
    async getCurrentNegotiationReport ({ commit }, id) {
        const res = await axios.get(`/price-negotiations/report/${id}`)
        commit('setCurrentNegotiation', res.data.data)
    },
    async deletePriceNegotiation (ctx, id) {
        return new Promise(((resolve, reject) => {
            axios.delete(`/price-negotiations/report/${id}`).then(resolve).catch(reject)
        }))
    }
}

export default {
    state,
    mutations,
    actions
}
