import axios from "axios"

const state = () => ({
    orderCorrection: {
        number: '',
        date: '',
        positions: [],
        common_amount_without_vat: '',
        common_amount_with_vat: '',
        provider_order: {
            number: '',
            order_date: ''
        }
    }
})

const mutations = {
    setOrderCorrection (state, data) {
        for (let key in data) {
            if (key !== 'positions') {
                state.orderCorrection[key] = data[key]
            }
        }
    },
    setOrderCorrectionPositions (state, data) {
        state.orderCorrection.positions = data
    }
}

const actions = {
    async getOrderCorrection ({ commit }, { orderId, id }) {
        const res = await axios.get(`/provider-orders/${orderId}/order-corrections/${id}`)
        commit('setOrderCorrection', res.data.data)
    },
    async getOrderCorrectionPositions ({ commit }, { id, orderId, filters = {} }) {
        const res = await axios.get(`/provider-orders/${orderId}/order-corrections/${id}/positions`, {
            params: filters
        })
        commit('setOrderCorrectionPositions', res.data)
    }
}

export default {
    state,
    mutations,
    actions
}
