import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import Keycloak from './plugins/keycloak';
import axios from 'axios';

//Компоненты и фильтры
import parseDate from '@/filters/parseDate';
import declensionNumbers from '@/filters/declensionNumbers';
import { VueMaskFilter } from 'v-mask';
import vSelect from 'vue-select';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

//Mixins
import Breadcrumbs from './mixins/breadcrumbs';

Vue.config.productionTip = false;

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_DEV_URL;
axios.defaults.headers.accept = 'application/json';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(Keycloak);

Vue.component('v-select', vSelect);

Vue.filter('parseDate', parseDate);
Vue.filter('declensionNumbers', declensionNumbers);
Vue.filter('VMask', VueMaskFilter);

Vue.mixin(Breadcrumbs);
import '@/assets/sass/main.sass';

async function initApp() {
  const token = Vue.$keycloak.token;
  axios.defaults.headers.authorization = 'Bearer ' + token;

  await store.dispatch('getUser');

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');

  // Vue.$keycloak.updateToken(30)
  //     .then(resp => {
  //         if (!resp) {
  //             router.push('/login')
  //         }
  //     })
  //     .catch(() => {
  //         router.push('/login')
  //     })
}

Vue.$keycloak.init({ onLoad: 'login-required' }).then(initApp).catch(initApp);
