import axios from "axios";

const state = () => ({
  isValid: false,
  isSave: false,
  document: null,
});

const mutations = {
  setValid(state, data) {
    state.isValid = data;
  },
  setDocument(state, data) {
    state.document = data;
  },
  setSave(state, data) {
    state.isSave = data;
  },
};

const actions = {
  async verifySignatureThumbprint({ commit }, { thumbprint }) {
    const data = {
      fingerprint: thumbprint,
    };

    let result = false;

    try {
      const res = await axios.post(`electronic_signatures/fingerprint`, data);

      if (res?.data && res?.data["Действителен"] === "Да") {
        result = true;
      }
    } catch (error) {
      console.error(error);
    }

    commit("setValid", result);
    return result;
  },

  async genDocumentConsignmentRegister({ commit }, { id }) {
    axios.defaults.maxBodyLength = Infinity;
    let res = {};

    try {
      res = await axios.get(`consignment-registers/${id}/genDocument`);
    } catch (error) {
      console.error(error);
    }

    commit("setDocument", null);
    return res?.data;
  },

  async saveSignatureConsignmentRegister({ commit }, { id, data }) {
    let res = {};

    try {
      res = await axios.post(`consignment-registers/${id}/saveSignature`, data);
    } catch (error) {
      console.error(error);
    }

    commit("setSave", null);
    return res?.data;
  },
};

export default {
  state,
  mutations,
  actions,
};
