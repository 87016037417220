import Vue from 'vue'
import Keycloak from 'keycloak-js'

const options = {
    url: 'https://openid.gridcom-rt.ru:8443/',
    realm: 'dev',
    clientId: 'test-lkk-app',
    clientSecret: 'BVdFl0Dc3gpOcemkwfLQO0eNv0N9Zmn5'
}

const _keycloak = Keycloak(options)

const Plugin = {
    install(Vue) {
        Vue.$keycloak = _keycloak
    }
}

Plugin.install = Vue => {
    Vue.$keycloak = _keycloak
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak
            }
        }
    })
}

Vue.use(Plugin)

export default Plugin
