import axios from 'axios';
import router from '@/router';
import Vue from 'vue';

const state = () => ({
  currentAdjustment: {
    number: '',
    date: '',
    positions: [],
    provider_order: {
      number: '',
      order_date: '',
    },
  },
});

const mutations = {
  setAdjustmentData(state, data) {
    for (const key in data) {
      if (key !== 'positions') {
        Vue.set(state.currentAdjustment, key, data[key]);
      }
    }
  },
  setAdjustmentPositions(state, data) {
    state.currentAdjustment.positions = data;
    Vue.set(state.currentAdjustment, 'positions', data);
  },
};

const actions = {
  async getAdjustmentData({ commit }, { orderId, id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/provider-orders/${orderId}/requirement-corrections/${id}`)
        .then((res) => {
          commit('setAdjustmentData', res.data.data);
          resolve();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            router.push('/provider/purchase-orders');
          }
          reject();
        });
    });
  },
  async rejectAdjustment(ctx, { orderId, id, comment }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/provider-orders/${orderId}/requirement-corrections/${id}/reject`,
          {
            comment,
          }
        )
        .then(resolve)
        .catch(reject);
    });
  },
  async approveAdjustment(ctx, { orderId, id, comment }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/provider-orders/${orderId}/requirement-corrections/${id}/approve`,
          {
            comment,
          }
        )
        .then(resolve)
        .catch(reject);
    });
  },
  async rejectAdjustmentMtr(
    ctx,
    { orderId, id, rejected_position_ids, comment }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/provider-orders/${orderId}/requirement-corrections/${id}/reject-positions`,
          {
            rejected_position_ids,
            comment,
          }
        )
        .then(resolve)
        .catch(reject);
    });
  },
  async getAdjustmentPositions({ commit }, { id, orderId, filters = {} }) {
    const res = await axios.get(
      `/provider-orders/${orderId}/requirement-corrections/${id}/positions`,
      {
        params: filters,
      }
    );
    commit('setAdjustmentPositions', res.data);
  },
};

export default {
  state,
  mutations,
  actions,
};
