import axios from "axios";

const state = () => ({
    negotiations: [],
    currentNegotiation: {
        number: '',
        date: '',
        positions: []
    }
})

const mutations = {
    setNegotiations (state, data) {
        state.negotiations = data
    },
    setCurrentNegotiation (state, data) {
        state.currentNegotiation = data
    }
}

const actions = {
    async getNegotiations ({ commit }, filters) {
        let params = {}
        if (filters) {
            params = filters
        }
        const res = await axios.get('/price-negotiations', {
            params
        })
        commit('setNegotiations', res.data)
    },
    async getCurrentNegotiation ({ commit }, id) {
        const res = await axios.get(`/price-negotiations/${id}`)
        commit('setCurrentNegotiation', res.data.data)
    },
    async deletePriceNegotiation (ctx, id) {
        return new Promise(((resolve, reject) => {
            axios.delete(`/price-negotiations/${id}`).then(resolve).catch(reject)
        }))
    }
}

export default {
    state,
    mutations,
    actions
}
