export default function (role) {
    switch (role) {
        case 'contractor':
            return 'Подрядчик'
        case 'provider':
            return 'Поставщик'
        case 'admin':
            return 'Администратор'
        case 'methodologist':
            return 'Методолог'
        default:
            return ''
    }
}
