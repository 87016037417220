import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import menu from "./modules/menu";
import notifications from "./modules/notifications";
import main from "./modules/main";
import orders from "./modules/orders";
import mtr from "./modules/mtr";
import report from "./modules/report";
import providerOrders from "./modules/providerOrders";
import registries from "./modules/registries";
import orderCorrection from "./modules/orderCorrection";
import adjustment from "./modules/adjustment";
import consignmentNotes from "./modules/consignmentNotes";
import consignmentNotesRegistries from "./modules/consignmentNotesRegistries";
import providerNotifications from "./modules/providerNotifications";
import priceNegotiations from "./modules/priceNegotiations";
import priceNegotiationsReport from "./modules/priceNegotiationsReport";
import createPriceNegotiation from "./modules/createPriceNegotiation";
import nsi from "./modules/nsi";
import referenceBooks from "./modules/referenceBooks";
import nomenclature from "./modules/nomenclature";
import createOrder from "./modules/createOrder";
import glossary from "./modules/glossary";
import file from "./modules/file";
import breadcrumbs from "./modules/breadcrumbs";
import signature from "./modules/signature";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    menu,
    main,
    notifications,
    orders,
    mtr,
    report,
    providerOrders,
    registries,
    orderCorrection,
    adjustment,
    consignmentNotes,
    consignmentNotesRegistries,
    providerNotifications,
    priceNegotiations,
    priceNegotiationsReport,
    createPriceNegotiation,
    nsi,
    referenceBooks,
    nomenclature,
    createOrder,
    glossary,
    file,
    breadcrumbs,
    signature,
  },
});
