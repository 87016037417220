import axios from 'axios'

const state = () => ({
    organizations: [],
    workAgreements: [],
    providersContracts: [],
    objects: [],
    contrAgents: [],
    nomenclature: []
})

const getters = {
    getOrganization: (state) => (id) => {
        const obj = state.organizations.filter(object => object.id === id)
        return obj.length ? obj[0] : {}
    },
    getWorkAgreement: (state) => (id) => {
        const obj = state.workAgreements.filter(object => object.id === id)
        return obj.length ? obj[0] : {}
    },
    getObject: (state) => (id) => {
        const obj = state.objects.filter(object => object.id === id)
        return obj.length ? obj[0] : {}
    },
    getSubObject: (state) => (id) => {
        let obj = {}
        state.objects.forEach(object => {
            object.sub_objects?.forEach(sub_object => {
                if (sub_object.id === id) {
                    obj = sub_object
                }
            })
        })
        return obj
    },
    getContrAgent: (state) => (id) => {
        const obj = state.contrAgents.filter(object => object.id === id)
        return obj.length ? obj[0] : {}
    },
    getProviderContract: (state) => (id) => {
        const obj = state.providersContracts.filter(object => object.id === id)
        return obj.length ? obj[0] : {}
    },
    getContrAgentsOptions(state, getters, rootState) {
        const userContrAgentId = rootState?.account?.user?.contr_agent?.id
        const res = [{ value: '', text: 'Все' }];
        ( Array.isArray( state.contrAgents ) ? state.contrAgents : [] ).forEach(item => {
            if (item.id !== userContrAgentId) {
                res.push({
                    value: item.id,
                    text: item.name
                })
            }
        })
        return res
    },
    getProvidersContractsOptions(state) {
        return [
            {value: '', text: 'Все'},
            ...state.providersContracts.map(item => ({
                value: item.id,
                text: item.number
            }))
        ]
    },
    getWorkAgreementsOptions(state) {
        return [
            {value: '', text: 'Все'},
            ...state.workAgreements.map(item => ({
                value: item.id,
                text: item.number
            }))
        ]
    },
    getOrganizationsOptions(state) {
        return [
            {value: '', text: 'Все'},
            ...state.organizations.map(item => ({
                value: item.id,
                text: item.name
            }))
        ]
    },
    getObjectsOptions(state) {
        return state.objects.map(item => {
            return {
                value: item.id,
                text: item.name
            }
        })
    },
    getSubObjectsOptions: (state) => (id) => {
        let res = [
            {
                value: null,
                text: 'Не указано'
            }
        ]
        state.objects.forEach(item => {
            if (item.id === id) {
                res.push(...item.sub_objects.map(item => {
                    return {
                        value: item.id,
                        text: item.name
                    }
                }))
            }
        })
        return res
    }
}

const mutations = {
    setReferenceBooks(state, {book, data}) {
        state[book] = data
    }
}

const actions = {
    async getReferenceBooks({commit}) {
        axios.get('/references/organizations').then(resp => commit('setReferenceBooks', {
            book: 'organizations',
            data: resp.data.data
        }))
        axios.get('/references/work_agreements').then(resp => commit('setReferenceBooks', {
            book: 'workAgreements',
            data: resp.data.data
        }))
        axios.get('/references/provider_contracts').then(resp => commit('setReferenceBooks', {
            book: 'providersContracts',
            data: resp.data.data
        }))
        axios.get('/references/objects').then(resp => commit('setReferenceBooks', {
            book: 'objects',
            data: resp.data.data
        }))
        axios.get('/references/contr_agents').then(resp => commit('setReferenceBooks', {
            book: 'contrAgents',
            data: resp.data.data.data
        }))
        axios.get('/references/nomenclature').then(resp => {
            commit('setReferenceBooks', {
                book: 'nomenclature',
                data: resp.data.data
            })
        })
    },
    async getNomenclaturesWithFilters ({ commit }, filters = {}) {
        await axios.get('/references/nomenclature', {
            params: filters
        }).then(resp => {
            commit('setReferenceBooks', {
                book: 'nomenclature',
                data: resp.data.data
            })
        })
    },
    async getContragentsWithFilters ({ commit }, filters = {}) {
        await axios.get('/references/contr_agents', {
            params: filters
        }).then(resp => {
            commit('setReferenceBooks', {
                book: 'contrAgents',
                data: resp.data.data.data
            })
        })
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
