const state = () => ({
    menu: [
        {
            url: '/',
            title: 'Главная'
        },
        {
            url: '#',
            title: 'Заказы',
            childrens: [
                {
                    url: '#',
                    title: 'Заказы ПО',
                    childrens: [
                        {
                            url: '/purchase-orders',
                            title: 'Заказы на поставку'
                        },
                        {
                            url: '/consignment-notes',
                            title: 'Товарные накладные'
                        },
                        {
                            url: '/consignment-notes-registries',
                            title: 'Реестры товарных накладных'
                        },
                        {
                            url: '/registries',
                            title: 'Реестры платежей'
                        }
                    ]
                },
                {
                    url: '#',
                    title: 'Заказы СК',
                    for: 'provider',
                    childrens: [
                        {
                            url: '/provider/purchase-orders',
                            title: 'Заказы поставщику'
                        },
                        {
                            url: '/provider-notifications',
                            title: 'Уведомления'
                        }
                    ]
                }
            ]
        },
        {
            url: '#',
            title: 'НСИ',
            childrens: [
                {
                    url: '/price-negotiations',
                    title: 'Запросы на согласование цен'
                },
                {
                    url: '#',
                    title: 'Запросы на добавление НСИ',
                    childrens: [
                        {
                            url: '/nsi-list',
                            title: 'Номенклатура'
                        },
                        {
                            url: '/',
                            title: 'Объекты'
                        }
                    ]
                },
                {
                    url: '/reference-books',
                    title: 'Справочники'
                }
            ]
        }
    ]
})

export default {
    state
}
