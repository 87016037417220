import axios from "axios";

const state = () => ({
    work_types: [],
    consignment_registers: {
        contr_agent_statuses: [],
        customer_statuses: [],
        provider_statuses: []
    },
    payment_registers: {
        positions: {
            payment_types: []
        },
        provider_statuses: []
    },
    vat_rates: [],
    countries: [],
    provider_orders: {
        stages: {}
    },
    statuses: [
        {text: 'Все', value: ''},
        'Не согласовано',
        'Согласовано',
        'Черновик',
        'На рассмотрении',
        'Аннулировано',
        'Самозакуп',
        'Согласовано частично',
        'Завершен'
    ]
})

const getters = {
    getCustomerStatusesOptions(state) {
        return ['Все', ...state.consignment_registers.customer_statuses]
    },
    getProviderStatusesOptions(state) {
        return ['Все', ...state.payment_registers.provider_statuses]
    },
    getVatRatesOptions(state) {
        let res = []
        for (let key in state.vat_rates) {
            res.push({
                text: state.vat_rates[key],
                value: parseFloat(key)
            })
        }
        return res
    },
    getCountriesOptions(state) {
        let res = []
        for (let key in state.countries) {
            res.push({
                text: state.countries[key].name,
                value: key
            })
        }
        return res
    },
    getPaymentTypesOptions(state) {
      return state.payment_registers.positions.payment_types
    },
    getOrderStagesOptions (state) {
        let res = []
        for (let key in state.provider_orders.stages) {
            res.push({
                text: state.provider_orders.stages[key],
                value: key
            })
        }
        return res
    },
    getVatRate: (state) => (id) => {
        return state.vat_rates[id]
    },
    getWorkTypesOptions(state) {
        return [{text: 'Все', value: ''}, ...state.work_types]
    },
    getStatuses(state) {
        return state.statuses
    }
}

export const mutations = {
    setGlossary(state, data) {
        for (let key in state) {
            if (data[key]) {
                state[key] = data[key]
            }
        }
    }
}

export const actions = {
    async getGlossary({ commit }) {
        const res = await axios.get('/references/glossary')
        commit('setGlossary', res.data.data)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
