import Vue from 'vue';
import axios from 'axios';

const state = () => ({
  consignmentNotesRegistries: {
    data: [],
  },
  currentConsignmentNotesRegistry: {
    number: '',
    date: '',
    positions: {
      data: [],
    },
  },
});

const mutations = {
  setConsignmentNotesRegistries(state, data) {
    state.consignmentNotesRegistries = data;
  },
  setConsignmentNotesRegistry(state, data) {
    console.log( 'setConsignmentNotesRegistry' )
    console.log( data )
    // for (let key in data) {
      // state.currentConsignmentNotesRegistry[key] = data[key];
      // Vue.set( state.currentConsignmentNotesRegistry, [key], data[key] )
    // }
    Vue.set( state, 'currentConsignmentNotesRegistry', data  )
    console.log( state.currentConsignmentNotesRegistry )
  },
  setConsignmentNoteRegistryPositions(state, data) {
    state.currentConsignmentNotesRegistry.positions = data;
  },
};

const actions = {
  async getConsignmentNotesRegistries({ commit }, filters = {}) {
    const res = await axios.get('/consignment-registers', {
      params: filters,
    });
    commit('setConsignmentNotesRegistries', res.data);
  },
  async getConsignmentNotesRegistry({ commit }, id) {
    const res = await axios.get(`/consignment-registers/${id}`);
    commit('setConsignmentNotesRegistry', res.data);
  },
  async getSearchConsignments(
    ctx,
    {
      organization_id,
      provider_contr_agent_id,
      contractor_contr_agent_id,
      work_agreement_id,
      customer_object_id,
      customer_sub_object_id,
    }
  ) {
    const res = await axios.get('/consignment-registers/search-consignments', {
      params: {
        organization_id,
        provider_contr_agent_id,
        contractor_contr_agent_id,
        work_agreement_id,
        customer_object_id,
        customer_sub_object_id,
      },
    });
    return res.data.data;
  },
  createConsignmentNoteRegistry(ctx, data) {
    const formData = new FormData()
    for (let key in data) {
        const value = data[key]

        if (value !== null) {
            if (Array.isArray(value)) {
                value.forEach((obj, i) => {
                    for (let objKey in obj) {
                        formData.append(`${key}[${i}][${objKey}]`, obj[objKey])
                    }
                })
            } else {
                formData.append(key, value)
            }
        }
    }
    return new Promise((resolve, reject) => {
      axios
        .post('/consignment-registers/create', formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateConsignmentNoteRegistry(ctx, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/consignment-registers/${id}`, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async deleteConsigmentNoteRegistry(ctx, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/consignment-registers/${id}`).then(resolve).catch(reject);
    });
  },
  async rejectConsigmentNoteRegistry(ctx, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/consignment-registers/${id}/reject`)
        .then(resolve)
        .catch(reject);
    });
  },
  async approveConsigmentNoteRegistry(ctx, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/consignment-registers/${id}/approve`)
        .then(resolve)
        .catch(reject);
    });
  },
  async getConsigmentNoteRegistryPositions({ commit }, { id, filters = {} }) {
    const res = await axios.get(`/consignment-registers/${id}/positions`, {
      params: filters,
    });
    commit('setConsignmentNoteRegistryPositions', res.data);
  },
};

export default {
  state,
  mutations,
  actions,
};
