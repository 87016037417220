<template>

    <div class="breadcrumbs">
        <ul class="d-flex align-items-center pt-2 pb-1">
            <template v-for="(link, index) in breadcrumbs">
                <li
                    v-if="index < (breadcrumbs.length - 1)"
                    :key="index"
                    class="mr-2 d-flex align-items-center">
                    <router-link
                        :to="link.link"
                        v-text="link.title"
                        class="text-primary"/>
                    <BIcon
                        class="ml-2"
                        style="width: 0.83rem;height: 0.83rem;"
                        icon="chevron-right"/>
                </li>
                <li
                    v-else
                    :key="index">
                <span
                    v-text="link.title"
                    class="text-muted"/>
                </li>
            </template>
        </ul>
    </div>

</template>

<script>
export default {
    name: "Breadcrumb",
    computed: {
        breadcrumbs () {
            return this.$store.state.breadcrumbs.list
        }
    }
}
</script>

<style scoped>

</style>
