import axios from "axios"

const actions = {
    updatePosition(ctx, { orderId, id, key, value }) {
        let data = {}
        data[key] = value
        return new Promise((resolve, reject) => {
            axios.patch(`/orders/${orderId}/positions/${id}`, data).then(resolve).catch(reject)
        })
    }
}

export default {
    actions
}
