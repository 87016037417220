import axios from "axios"

const state = () => ({
    report: {
        top_report: {},
        bottom_report: {
            data: []
        }
    }
})

const mutations = {
    setReport (state, data) {
        state.report = data
    }
}

const actions = {
    async getReport ({ commit }, { id, filters = {} }) {
        const res = await axios.get(`/orders/${id}/report`, {
            params: filters
        })
        commit('setReport', res.data.data)
    }
}

export default {
    state,
    mutations,
    actions
}
