import axios from "axios";

const state = () => ({
    consignmentNotes: {
        data: []
    },
    currentConsignmentNote: {
        number: '',
        date: '',
        positions_sum_amount_with_vat: 0,
        positions_sum_amount_without_vat: 0,
        positions: {
            data: []
        }
    },
    consignmentsSearchOrders: []
})

const mutations = {
    setConsignmentNotes (state, data) {
        state.consignmentNotes = data
    },
    setCurrentConsignmentNote (state, data) {
        for (let key in data) {
            if (key !== 'positions') {
                state.currentConsignmentNote[key] = data[key]
            }
        }
    },
    setConsignmentPositions (state, data) {
        state.currentConsignmentNote.positions = data
    }
}

const actions = {
    async getConsignmentNotes ({ commit }, filters = {}) {
        const res = await axios.get('/consignments', {
            params: filters
        })
        commit('setConsignmentNotes', res.data)
    },
    async getConsignment ({ commit }, id) {
        const res = await axios.get(`/consignments/${id}`)
        commit('setCurrentConsignmentNote', res.data.data)
    },
    async getConsignmentPositions ({ commit }, { id, filters = {} }) {
        const res = await axios.get(`/consignments/${id}/positions`, {
            params: filters
        })
        commit('setConsignmentPositions', res.data.data)
    },
    async getConsignmentsSearchOrders (ctx, {
        organization_id,
        provider_contr_agent_id,
        provider_contract_id,
        work_agreement_id,
        contractor_contr_agent_id,
        customer_object_id,
        customer_sub_object_id,
    }) {
        const res = await axios.get('/consignments/search-orders', {
            params: {
                organization_id,
                provider_contr_agent_id,
                provider_contract_id,
                work_agreement_id,
                contractor_contr_agent_id,
                customer_object_id,
                customer_sub_object_id
            }
        })
        return res.data
    },
    createConsignmentNote(ctx, data) {
        return new Promise((resolve, reject) => {
            axios.post('/consignments/create', data).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    updateConsignmentNote(ctx, {id, data}) {
        return new Promise((resolve, reject) => {
            axios.put(`/consignments/${id}`, data).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    async deleteConsigmentNote(ctx, id) {
        return new Promise(((resolve, reject) => {
            axios.delete(`/consignments/${id}`).then(resolve).catch(reject)
        }))
    }
}

export default {
    state,
    mutations,
    actions
}
