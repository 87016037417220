import axios from "axios"

const state = () => ({
    registries: {
        data: []
    },
    registriesFields: [
        {
            key: 'number',
            sortable: true,
            label: '№',
            full: 'Номер реестра',
            openDefault: true
        },
        {
            key: 'date',
            sortable: true,
            label: 'Дата',
            full: 'Дата',
            openDefault: true
        },
        {
            key: 'contractor',
            nesting: 'contractor_contr_agent_id',
            sortable: true,
            label: 'Подрядчик',
            full: 'Подрядчик',
            openDefault: true
        },
        {
            key: 'provider',
            nesting: 'provider_contr_agent_id',
            sortable: true,
            label: 'Поставщик',
            full: 'Поставщик',
            openDefault: true
        },
        {
            key: 'provider_contract_number',
            nesting: 'provider_contract_id_number',
            sortable: true,
            label: '№ договора поставки',
            full: '№ договора поставки',
            openDefault: true
        },
        {
            key: 'provider_contract_date',
            nesting: 'provider_contract_id_date',
            sortable: true,
            label: 'Дата договора поставки',
            full: 'Дата договора поставки',
            openDefault: true
        },
        {
            key: 'provider_status',
            sortable: true,
            label: 'Статус согласования с поставщиком',
            full: 'Статус согласования с поставщиком',
            openDefault: true
        },
        {
            key: 'responsible_full_name',
            sortable: true,
            label: 'Ответственный исполнитель',
            full: 'Ответственный исполнитель',
            openDefault: true
        },
        {
            key: 'responsible_phone',
            sortable: true,
            label: 'Телефон',
            full: 'Телефон ответственного исполнителя',
            openDefault: true
        },
        {
            key: 'comment',
            sortable: false,
            label: 'Комм',
            full: 'Комментарий',
            openDefault: true
        }
    ],
    currentRegistry: {
        number: '',
        date: '',
        positions_sum_amount_payment: 0,
        positions: {
            data: []
        }
    }
})

const mutations = {
    setRegistries (state, array) {
        state.registries = array
    },
    setCurrentRegistry (state, data) {
        for (let key in data) {
            if (key !== 'positions') {
                state.currentRegistry[key] = data[key]
            }
        }
    },
    setRegistryPositions (state, data) {
        state.currentRegistry.positions = data
    }
}

const actions = {
    async getRegistries ({ commit }, filters = {}) {
        const res = await axios.get('/payment-registers', {
            params: filters
        })
        commit('setRegistries', res.data)
    },
    async getCurrentRegistry ({ commit }, id) {
        const res = await axios.get(`/payment-registers/${id}`)
        commit('setCurrentRegistry', res.data.data)
    },
    async getRegistryPositions ({ commit }, { id, filters = {} }) {
        const res = await axios.get(`/payment-registers/${id}/positions`, {
            params: filters
        })
        commit('setRegistryPositions', res.data.data)
    },
    async getRegistrySearchOrders (ctx, {
        provider_contr_agent_id,
        contractor_contr_agent_id,
        provider_contract_id
    }) {
        const res = await axios.get('/payment-registers/search-orders', {
            params: {
                provider_contr_agent_id,
                contractor_contr_agent_id,
                provider_contract_id
            }
        })
        return res.data.data
    },
    async createRegistry(ctx, data) {
        return new Promise((resolve, reject) => {
            axios.post('/payment-registers/create', data).then(resp => {
                console.log(resp)
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    async deleteRegistry(ctx, id) {
        return new Promise(((resolve, reject) => {
            axios.delete(`/payment-registers/${id}`).then(resolve).catch(reject)
        }))
    },
    async rejectRegistry(ctx, id) {
        return new Promise(((resolve, reject) => {
            axios.post(`/payment-registers/${id}/reject`).then(resolve).catch(reject)
        }))
    },
    async approveRegistry(ctx, id) {
        return new Promise(((resolve, reject) => {
            axios.post(`/payment-registers/${id}/approve`).then(resolve).catch(reject)
        }))
    },
    updateRegistry(ctx, {id, data}) {
        return new Promise((resolve, reject) => {
            axios.put(`/payment-registers/${id}`, data).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    state,
    mutations,
    actions
}
