import axios from "axios"

const actions = {
    async getPriceNegotiationOrders (ctx, params) {
        const res = await axios.get('/price-negotiations/search-orders', {
            params
        })
        return res.data
    },
    createPriceNegotiation(ctx, data) {
        const formData = new FormData()
        for (let key in data) {
            const value = data[key]

            if (value !== null) {
                if ( key === 'files' ) {
                    data[ key].forEach( ( value, idx ) => {
                        formData.append( `${ key }[${ idx }]`, value )
                    } )
                } else if (Array.isArray(value)) {
                    value.forEach((obj, i) => {
                        for (let objKey in obj) {
                            formData.append(`${key}[${i}][${objKey}]`, obj[objKey])
                        }
                    })
                } else {
                    formData.append(key, value)
                }
            }
        }
        return new Promise((resolve, reject) => {
            axios.post('/price-negotiations', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    updatePriceNegotiation(ctx, {id, data}) {
        const formData = new FormData()
        for (let key in data) {
            const value = data[key]

            if (value !== null) {
                if ( key === 'files' ) {
                    data[ key].forEach( ( value, idx ) => {
                        formData.append( `${ key }[${ idx }]`, value )
                    } )
                } else if (Array.isArray(value)) {
                    value.forEach((obj, i) => {
                        for (let objKey in obj) {
                            formData.append(`${key}[${i}][${objKey}]`, obj[objKey])
                        }
                    })
                } else {
                    formData.append(key, value)
                }
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`/price-negotiations/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default {
    actions
}
