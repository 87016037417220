import axios from "axios"

const state = () => ({
    notificationsList: [],
    currentNotification: {
        id: '',
        date: '',
        positions: []
    }
})

const mutations = {
    setProviderNotifications (state, data) {
        state.notificationsList = data
    },
    setCurrentNotification (state, data) {
        for (let key in data) {
            if (key !== 'positions') {
                state.currentNotification[key] = data[key]
            }
        }
    },
    setNotificationPositions (state, data) {
        state.currentNotification.positions = data
    }
}

const actions = {
    async getProviderNotifications ({ commit }, filters = {}) {
        const res = await axios.get('/organization-notifications', {
            params: filters
        })
        commit('setProviderNotifications', res.data)
    },
    async getCurrentNotification ({ commit }, id) {
        const res = await axios.get(`/organization-notifications/${id}`)
        commit('setCurrentNotification', res.data.data)
    },
    async getProviderNotificationContracts (ctx, {
        organization_id,
        provider_contr_agent_id
    }) {
        const res = await axios.get('/organization-notifications/search-contracts', {
            params: {
                organization_id,
                provider_contr_agent_id
            }
        })
        return res.data.data
    },
    async getProviderNotificationsSearchOrders(ctx, {
        organization_id,
        provider_contr_agent_id,
        contract_number,
        contract_date,
        contract_stage
    }) {
        const res = await axios.get('/organization-notifications/search-orders', {
            params: {
                organization_id,
                provider_contr_agent_id,
                contract_number,
                contract_date,
                contract_stage
            }
        })
        return res.data.data
    },
    createProviderNotification(ctx, data) {
        return new Promise((resolve, reject) => {
            axios.post('/organization-notifications', data).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    updateProviderNotification(ctx, {id, data}) {
        return new Promise((resolve, reject) => {
            axios.put(`/organization-notifications/${id}`, data).then(resp => {
                resolve(resp)
            }).catch(err => {
                reject(err)
            })
        })
    },
    async getNotificationPositions ({ commit }, { id, filters = {} }) {
        const res = await axios.get(`/organization-notifications/${id}/positions`, {
            params: filters
        })
        commit('setNotificationPositions', res.data.data)
    }
}

export default {
    state,
    mutations,
    actions
}
