<template>
    <div
        class="layout d-flex flex-column min-vh-100">

        <div class="header-top py-1">
            <div class="container">
                <div class="d-flex justify-content-between">

                    <HeaderLogo />

                </div>
            </div>
        </div>

        <main class="main pb-3 flex-grow-1 d-flex flex-column">

            <router-view />

        </main>

        <Footer />

    </div>
</template>

<script>

import HeaderLogo from '@/components/Header/Logo'
import Footer from '@/components/Footer'

export default {
    name: "Auth",
    components: {
        HeaderLogo,
        Footer
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
